.approve-business {
	/* margin: 50px; */
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: space-around;
	background: #e7e7e7;
	flex-wrap: wrap;
}

.approve-business .fa-check-circle {
	font-size: 200px;
	color: #0ec164;
}
.approve-business .fa-times-circle {
	font-size: 200px;
	color: rgb(139, 0, 0);
}
