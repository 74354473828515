@media screen and (max-width: 768px) {
	.profile-update {
		margin-top: 10px;

		h4 {
			font-weight: 800;
			border-bottom: 1px solid #333;
		}
	}
}
