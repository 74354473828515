@import 'styles/colors';

body {
	margin: 0;
	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: $backgroundColor;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.base-screen {
	// display: flex;
	// flex-direction: column;
	// height: 100vh;
	// background-color: $backgroundColor;
	// width: 100vw;
}

a {
	cursor: pointer;
}

// @media screen and (max-width: 768px) {
// 	.form-sm {
// 		display: block !important;
// 	}
// }
.auth-form .MuiGrid-spacing-xs-3 > .MuiGrid-item {
	padding: 0 15px 10px 15px;
}

#scrollableStoriesDiv .MuiGrid-grid-md-3 {
	padding: 10px;
}
#scrollableStoriesDiv .MuiGrid-grid-md-3,
#scrollableDiv .MuiGrid-grid-md-6 {
	padding: 10px;
}
::-webkit-scrollbar {
	width: 10px;
	height: 5px;
}
::-webkit-scrollbar-track {
	background: #f4f6f9;
	margin: 0 2px;
}
::-webkit-scrollbar-thumb {
	background: #c1c6d4;
	border-radius: 7px;
}
::-webkit-scrollbar-thumb:hover {
	background: #858c9c;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 .selected-flag {
	padding: 0 10px;
}
.intl-tel-input .country-list {
	width: 342px;
}

.selected-flag {
	display: flex !important;
	align-items: center !important;
}

.form-group .row > * {
	width: unset;
}
