@import 'src/styles/colors';
@import 'src/styles/variables';

// .router-container {
// 	// min-width: 1100px;
// 	flex-shrink: 0;
// 	background-color: $backgroundColor;
// 	position: relative;
// }

.app-container {
	padding: '15px';
}
