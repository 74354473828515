.overlay {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000; /* Specify a stack order in case you're using a different order for other elements */
}

.blur {
  filter: blur(4px);
  backdrop-filter: blur(4px);
}
